<template>
  <div>
    <div class="option-box">
      <div class="no-box"></div>
      <div class="textarea-box">
        <div class="textarea-box-item">
          <textarea
            placeholder="请输入你的意见：如希望增加的心愿祝福等"
            class="textarea"
            v-model="informations"
          />
        </div>
      </div>
      <div @click="sendFeedBackAddBtn" class="com-btn">
        <van-button type="default" round size="large">{{ informationBtn }}</van-button>
      </div>
    </div>
  </div>
</template>


<script >
import {Toast} from "vant"
export default {
  data() {
    return {
      optionsTitle: "意见反馈",
      informationBtn: "提交",
      informations: '',
    };
  },
  components: {

  },

  methods: {
    sendFeedBackAddBtn() {
      // console.log("发送了");
      if (this.informations) {
        // this.queryFeedbackAdd(this.informations);
        this.$router.replace("/my/option/success");
      } else {
        Toast({
          message: '请输入您的宝贵建议',
          position: 'top',
        });
      }

      // if (this.informations.trim()) {

      // }
    },
  },
};
</script>

<style scoped>
.com-btn {
  margin-top: 105px;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}
.option-box {
  height: 100vh;
  background-color: #fff;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #cccccc;
  font-size: 14px;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #cccccc;
  font-size: 14px;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #cccccc;
  font-size: 14px;
}
.textarea-box {
  margin: 20px;
  text-align: center;
}

.textarea {
  width: calc(100vw - 40px);
  height: 220px;
  border: none;
  resize: none;
  cursor: pointer;
  padding: 16px 17px;
  font-size: 14px;
  box-sizing: border-box;
  color: #000;
  background-color: #fafafa;
  border-radius: 4px;
}
</style>
